<template>
  <b-row no-gutters>
    <b-col cols="12" class="border-bottom">
      <h5 class="font-weight-bold p-2 mb-0 text-center bg-light">
        {{ subject }}
      </h5>
    </b-col>
    <b-col cols="12" class="p-2">
      <!-- INTRO -->
      <intro class="mb-3" :name="client.firstName" :text="introText" />

      <!-- PAYMENTS -->
      <div>
        <!-- SETTLED PAYMENTS -->
        <template v-if="clientPayments.length">
          <h4>
            <u>{{ $t("Pagaments realitzats", locale) }}</u>
          </h4>
          <b-list-group flush class="mt-2 mb-3">
            <b-list-group-item
              v-for="payment in clientPayments"
              :key="payment.uuid"
              class="d-flex justify-content-between align-items-center px-0"
            >
              <div>
                <div class="font-weight-bold">
                  {{ formatCurrency(payment.pvpAmount) }}
                </div>
                <div v-if="payment.method === 'SOURCE_PAYMENT'">
                  <small>
                    {{
                      $t("Gestionat per", locale, {
                        gestor: getOtaName(booking.source),
                      })
                    }}
                  </small>
                </div>
                <template v-else>
                  <div v-if="payment.date">
                    <small>
                      {{ formatDateStringToDate(payment.date) }}
                    </small>
                  </div>
                  <div v-if="payment.localizator">
                    <small> ID: {{ payment.localizator }} </small>
                  </div>
                </template>
              </div>
              <b-badge pill variant="success">
                {{ $t("Pagat", locale) }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>
        </template>

        <!-- PENDING PAYMENTS -->
        <template v-if="pendingWithSecurityDeposit > 0">
          <h4>
            <u>{{ $t("Pagaments pendents", locale) }}</u>
          </h4>

          <b-list-group flush class="my-2">
            <!-- ACCOMMODATION -->
            <b-list-group-item
              v-if="accommodationPendingPrice > 0"
              class="d-flex justify-content-between align-items-center px-0"
            >
              <div>
                <div class="font-weight-bold">
                  {{ $t("Allotjament", locale) }}
                </div>
                <small>{{ formatCurrency(accommodationPrice) }}</small>
              </div>
              <b-badge pill variant="danger">
                {{ $t("Pendent", locale) }}
                {{ formatCurrency(accommodationPendingPrice) }}
              </b-badge>
            </b-list-group-item>

            <!-- SECURITY DEPOSIT CHARGABLE BY THE AGENCY -->
            <b-list-group-item
              v-if="hasAgencyChargableSecurityDeposit"
              class="d-flex justify-content-between align-items-center px-0"
            >
              <div>
                <div class="font-weight-bold">
                  {{ $t("Fiança", locale) }}
                </div>
                <small>{{ formatCurrency(securityDepositPrice) }}</small>
              </div>
              <b-badge pill variant="danger">
                {{ $t("Pendent", locale) }}
                {{ formatCurrency(securityDepositPendingPrice) }}
              </b-badge>
            </b-list-group-item>

            <!-- CHARGABLE SERVICES -->
            <b-list-group-item
              v-for="(chargableService, index) in chargableServicesWithPending"
              :key="`chargable-service-${index}`"
              class="d-flex justify-content-between align-items-center px-0"
            >
              <div>
                <div class="font-weight-bold">
                  {{ getServiceName(chargableService.service, locale) }}
                </div>
                <small>
                  {{ getBookingServiceTotalPriceText(chargableService) }}
                </small>
              </div>
              <b-badge pill variant="danger">
                {{ $t("Pendent", locale) }}
                {{ formatCurrency(getServicePendingPrice(chargableService)) }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>

          <!-- PAY NOW BUTTON -->
          <b-button
            v-if="tpvLink"
            block
            variant="primary"
            :href="tpvLink"
            target="_blank"
            class="mb-3"
          >
            {{ $t("Paga ara", locale) }}
          </b-button>
        </template>

        <!-- ON SITE PAYMENTS -->
        <div v-if="onsitePayments.length">
          <h4>
            <u>{{ $t("Propers pagaments", locale) }}</u>
          </h4>
          <b-list-group flush class="my-2">
            <b-list-group-item
              v-if="onsitePayments.length"
              class="d-flex justify-content-between align-items-center px-0"
            >
              <div>
                <div class="font-weight-bold">
                  {{ $t("emails.common.onsitePayments", locale) }}
                </div>
                <div
                  v-for="(onsitePayment, index) in onsitePayments"
                  :key="`onsite-payment-${index}`"
                >
                  <small>
                    {{ onsitePayment.name }}
                    <span v-if="onsitePayment.price">
                      · {{ onsitePayment.price }}
                    </span>
                  </small>
                </div>
              </div>
              <b-badge pill variant="info">
                {{ $t("Programat", locale) }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BBadge,
  BButton,
} from "bootstrap-vue";
import {
  formatCurrency,
  formatDateObjectToDate,
  formatDateStringToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import {
  getBookingServiceClientTotalPrice,
  getBookingServicePendingPrice,
  getPaymentFrequencyUnit,
  getServiceName,
  getTpvLink,
  getOtaName,
} from "@/utils/methods";
import Intro from "@/views/bookings/booking/components/mail/Intro.vue";

export default {
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,
    Intro,
  },
  props: {
    locale: {
      type: String,
      default: "en-GB",
    },
    last: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: {},
      getServiceName,
      getOtaName,
      formatCurrency,
      formatDateObjectToDate,
      formatDateStringToDate,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    bookingLocalizator() {
      return this.booking?.localizator || null;
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    accommodationName() {
      return this.accommodation?.name || null;
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    clientPayments() {
      return this.$store.getters["booking/clientPayments"];
    },
    contentClientPayments() {
      return this.clientPayments.map((payment) => ({
        price: payment.pvpAmount,
        date: formatDateStringToDate(payment.date),
        localizator: payment.localizator,
        managed:
          payment.method === "SOURCE_PAYMENT"
            ? this.$t("Gestionat per", this.locale, {
                gestor: getOtaName(this.booking.source),
              })
            : null,
      }));
    },
    accommodationPrice() {
      return this.$store.getters["booking/subtotalDiscounted"];
    },
    accommodationPendingPrice() {
      return this.$store.getters["booking/subtotalPending"];
    },
    hasSecurityDeposit() {
      return this.$store.getters["booking/hasSecurityDeposit"];
    },
    hasChargableSecurityDeposit() {
      return this.$store.getters["booking/hasChargableSecurityDeposit"];
    },
    hasAgencyChargableSecurityDeposit() {
      return (
        this.hasSecurityDeposit &&
        this.hasChargableSecurityDeposit &&
        this.securityDepositPendingPrice > 0
      );
    },
    hasOwnerChargableSecurityDeposit() {
      return (
        this.hasSecurityDeposit &&
        !this.hasChargableSecurityDeposit &&
        this.securityDepositPrice > 0
      );
    },
    securityDepositPrice() {
      return this.$store.getters["booking/securityDepositPrice"];
    },
    securityDepositPendingPrice() {
      if (!this.hasChargableSecurityDeposit) {
        return 0;
      }

      return this.$store.getters["booking/securityDepositPendingPrice"] || 0;
    },
    pending() {
      return this.$store.getters["booking/pending"];
    },
    pendingWithSecurityDeposit() {
      return this.pending + this.securityDepositPendingPrice;
    },
    chargableServices() {
      return this.$store.getters["booking/chargableServices"];
    },
    chargableServicesWithPending() {
      return this.chargableServices.filter((chargableService) => {
        return this.getServicePendingPrice(chargableService) > 0;
      });
    },
    contentChargableServicesWithPending() {
      if (!this.chargableServicesWithPending?.length) return [];
      return this.chargableServicesWithPending.map((chargableService) => ({
        name: getServiceName(chargableService.service, this.locale),
        price: this.getBookingServiceTotalPrice(chargableService),
        pendingPrice: this.getServicePendingPrice(chargableService),
      }));
    },
    unchargableServices() {
      return this.$store.getters["booking/unchargableServices"];
    },
    onsitePayments() {
      const payments = [];

      // Add the security deposit if the owner charges it on arrival
      if (this.hasOwnerChargableSecurityDeposit) {
        payments.push({
          name: this.$t("Fiança", this.locale),
          price: formatCurrency(this.securityDepositPrice),
        });
      }

      // Add the rest of the unchargable services
      if (this.unchargableServices.length) {
        this.unchargableServices.forEach((bookingService) => {
          const { service } = bookingService || {};
          payments.push({
            name: getServiceName(service, this.locale),
            price: this.bookingServiceUnitPriceText(bookingService),
          });
        });
      }

      return payments;
    },
    contentOnsitePayments() {
      const payments = [];

      // Add the security deposit if the owner charges it on arrival
      if (this.hasOwnerChargableSecurityDeposit) {
        payments.push({
          name: this.$t("Fiança", this.locale),
          price: this.securityDepositPrice,
          unit: "",
        });
      }

      // Add the rest of the unchargable services
      if (this.unchargableServices.length) {
        this.unchargableServices.forEach((bookingService) => {
          const { service, pvpPrice, paymentFrequency } = bookingService || {};
          payments.push({
            name: getServiceName(service, this.locale),
            price: pvpPrice,
            unit: getPaymentFrequencyUnit(paymentFrequency, this.locale) || "",
          });
        });
      }

      return payments;
    },
    introText() {
      if (this.last) {
        return this.$t(
          "emails.clientFinalPaymentRequest.heavyIntro",
          this.locale,
          {
            localizator: this.bookingLocalizator,
            accommodationName: this.accommodationName,
          }
        );
      } else {
        return this.$t("emails.clientFinalPaymentRequest.intro", this.locale, {
          localizator: this.bookingLocalizator,
          accommodationName: this.accommodationName,
        });
      }
    },
    subject() {
      return `${this.$t(
        "emails.clientFinalPaymentRequest.subject",
        this.locale,
        {
          localizator: this.bookingLocalizator,
          accommodationName: this.accommodationName,
        }
      )}`;
    },
    tpvLink() {
      if (this.pendingWithSecurityDeposit <= 0) {
        return null;
      }

      return getTpvLink({
        locale: this.locale,
        accommodation: this.accommodationName,
        localizator: this.bookingLocalizator,
        checkin: formatDateStringToDatabaseDate(this.booking.checkin),
        checkout: formatDateStringToDatabaseDate(this.booking.checkout),
        amount:
          this.pendingWithSecurityDeposit > 0
            ? (this.pendingWithSecurityDeposit / 100).toFixed(2)
            : null,
        name: this.client?.fullName || null,
        comment: `${this.$t("Pagament final", this.locale)}+${
          this.bookingLocalizator
        }`,
      });
    },
  },
  watch: {
    content: {
      deep: true,
      handler() {
        this.$emit("content-updated", this.content);
      },
    },
  },
  created() {
    this.initContent();
  },
  methods: {
    initContent() {
      this.content = {
        locale: this.locale,
        subject: this.subject,
        clientEmail: this.client?.email || null,
        clientName: this.client?.firstName || null,
        localizator: this.booking?.localizator || null,
        accommodationName: this.accommodation?.name || null,
        clientPayments: this.contentClientPayments,
        pendingWithSecurityDeposit: this.pendingWithSecurityDeposit,
        accommodationPrice: this.accommodationPrice,
        accommodationPendingPrice: this.accommodationPendingPrice,
        hasSecurityDeposit: this.hasSecurityDeposit,
        hasAgencyChargableSecurityDeposit:
          this.hasAgencyChargableSecurityDeposit,
        hasChargableSecurityDeposit: this.hasChargableSecurityDeposit,
        securityDepositPrice: this.securityDepositPrice,
        securityDepositPendingPrice: this.securityDepositPendingPrice,
        chargableServices: this.contentChargableServicesWithPending,
        paymentLink: this.tpvLink,
        onsitePayments: this.contentOnsitePayments,
        last: this.last,
      };
    },
    bookingServiceUnitPriceText(bookingService) {
      const { paymentFrequency, pvpPrice, chargable } = bookingService || {};

      if (
        !bookingService ||
        !paymentFrequency ||
        (paymentFrequency === "ONCE" && chargable)
      ) {
        return null;
      }

      return `${formatCurrency(pvpPrice)}${
        getPaymentFrequencyUnit(paymentFrequency, this.locale) || ""
      }`;
    },
    getBookingServiceTotalPrice(bookingService) {
      return getBookingServiceClientTotalPrice(
        bookingService,
        this.booking.nights
      );
    },
    getBookingServiceTotalPriceText(bookingService) {
      return formatCurrency(this.getBookingServiceTotalPrice(bookingService));
    },
    getServicePendingPrice(bookingService) {
      return getBookingServicePendingPrice(
        bookingService,
        this.booking?.nights
      );
    },
  },
};
</script>
